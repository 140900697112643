<script setup>
import { sendBusMessage } from '@/services/message-bus'
import { useSession } from '@/stores/session'
import { useStats } from '@/stores/stats'
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'

import DevSignOutButton from '@/components/dev/DevSignOutButton.vue'

import SldsIllustrationNoAccess from '@/components/slds/SldsIllustrationNoAccess.vue'
import SldsIllustrationWalkthroughNotAvailable from '@/components/slds/SldsIllustrationWalkthroughNotAvailable.vue'
import SldsIllustrationSetup from './components/slds/SldsIllustrationSetup.vue'
import SldsSpinner from './components/slds/SldsSpinner.vue'

const isStandalone = import.meta.env.VITE_IS_STANDALONE === 'true'

const route = useRoute()
const sessionStore = useSession()
const statsStore = useStats()

const appContainer = ref(null)
const { height } = useElementSize(appContainer)

watch(
  height,
  newHeight => {
    if (sessionStore.contextMessage) {
      if (sessionStore.contextMessage.packageVersion > 0) {
        sendBusMessage({
          action: 'resizeFrame',
          source: route.name,
          height: `${newHeight}px`,
        })
      } else {
        sendBusMessage({
          action: 'resizeFrame',
          source: route.name,
          height: newHeight,
        })
      }
    }
  },
  { immediate: true },
)
</script>

<template>
  <div ref="appContainer">
    <div
      v-if="
        sessionStore.isSessionExpired &&
        route.name &&
        route.name !== 'admin-general-settings-setup'
      "
      style="height: 100vh"
      class="d-flex flex-column align-center justify-center"
    >
      <SldsIllustrationNoAccess
        title="Your Session has expired!"
        description="Please refresh this page and try again."
      ></SldsIllustrationNoAccess>

      <DevSignOutButton v-if="isStandalone" class="mt-4"></DevSignOutButton>
    </div>

    <div
      v-else-if="sessionStore.isStartingPageLoading"
      style="height: 100vh"
      class="d-flex flex-column align-center justify-center"
    >
      <SldsSpinner></SldsSpinner>
    </div>

    <div
      v-else-if="sessionStore.isUserError || statsStore.isMetadataError"
      style="height: 100vh"
      class="d-flex flex-column align-center justify-center bg-white"
    >
      <SldsIllustrationWalkthroughNotAvailable
        title="Something went wrong!"
        description="Please try again later."
      ></SldsIllustrationWalkthroughNotAvailable>
    </div>

    <div
      v-else-if="
        !sessionStore.stepper?.isFinished &&
        route.name &&
        route.name !== 'admin-general-settings-setup'
      "
      style="height: 100vh"
      class="d-flex flex-column align-center justify-center bg-white"
    >
      <SldsIllustrationSetup
        title="ActivePrime Setup Not Finished"
        description="Please re-open the CleanData Add-on from the Heroku app to finish the configuration."
      ></SldsIllustrationSetup>
    </div>

    <router-view v-else></router-view>
  </div>
</template>

<style>
@import 'intro.js/introjs.css';
@import '@salesforce-ux/design-system/css/cards/base/index.css';

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-grab {
  cursor: grab;
}

.slds-spinner {
  z-index: 1800 !important;
}

.dp-red-border {
  border-color: rgb(var(--v-theme-ap-red)) !important;
}
</style>
